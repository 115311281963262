:root {
  --textColor: #1D1D1D;
}
:root {
  --spacePart: 10px;
}
@media (max-width: 1023px) {
  :root {
    --spacePart: 10px;
  }
}
@media (max-width: 767px) {
  :root {
    --spacePart: 10px;
  }
}
:root {
  --spaceUnit: 20px;
}
@media (max-width: 1023px) {
  :root {
    --spaceUnit: 20px;
  }
}
@media (max-width: 767px) {
  :root {
    --spaceUnit: 10px;
  }
}
:root {
  --spaceTotal: 30px;
}
@media (max-width: 1023px) {
  :root {
    --spaceTotal: 30px;
  }
}
@media (max-width: 767px) {
  :root {
    --spaceTotal: 20px;
  }
}
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
#home {
  float: left;
  height: 34px;
}
.section--header .desk {
  width: calc(100% - 100px);
  max-width: calc(100% - 100px);
}
.section--header {
  padding: 40px 0 30px;
  box-sizing: border-box;
}
.section--sidemood .mood {
  width: 70%;
  float: right;
  margin-top: -130px;
}
.footarea {
  margin-top: 40px;
  margin-bottom: 20px;
}
.footpart {
  margin-top: 20px;
  margin-bottom: 20px;
}
.footlogo {
  width: 100px;
  margin-bottom: 35px;
}
.contact {
  margin-top: 35px;
}
@media (max-width: 1023px) {
  #social .meta {
    width: 68px;
    height: 68px;
  }
}
#legal {
  font-size: 18px;
  line-height: 1.55555556;
}
h1 {
  font-size: 20px;
  line-height: 1.5;
}
.cb-layout1 h1 {
  font-size: 40px;
  line-height: 1.25;
}
.cb-layout2 h1,
.cb-layout4 h1,
.cb-layout5 h1 {
  font-size: 45px;
  line-height: 1.22222222;
}
h2 {
  font-size: 20px;
  line-height: 1.5;
}
h3 {
  font-size: 40px;
  line-height: 1.25;
}
h4 {
  font-size: 25px;
  line-height: 1.4;
}
.section--four .seam.slim h4 {
  font-size: 22px;
  line-height: 1.45454545;
}
h5 {
  font-size: 22px;
  line-height: 1.45454545;
}
p.loud {
  font-size: 25px;
  line-height: 1.4;
}
.section--four .seam.slim p.loud {
  font-size: 22px;
  line-height: 1.45454545;
}
.list--bullet li {
  font-size: 21px;
  line-height: 1.42857143;
}
.wrapper {
  font-size: 20px;
  line-height: 1.5;
}
.section--one .area {
  width: 100%;
}
.section--one .area > .unit {
  margin-right: 1.57894737%;
  margin-left: 1.57894737%;
  width: 96.84210526%;
}
.section--one .area .cb-album .body,
.section--one .area .head,
.section--one .area .foot,
.section--one .area .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--one .area > .slim {
  width: 46.84210526%;
}
.section--one .area > .slim .head,
.section--one .area > .slim .foot,
.section--one .area > .slim .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--one .area > .slim.cb-album .body {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--one .area > .slim .tiny {
  width: 100%;
}
.section--four .area {
  width: 100%;
}
.section--four .area > .unit {
  margin-right: 3.09278351%;
  margin-left: 3.09278351%;
  width: 93.81443299%;
}
.section--four .area .cb-album .body,
.section--four .area .head,
.section--four .area .foot,
.section--four .area .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--four .area > .slim {
  width: 43.81443299%;
}
.section--four .area > .slim .head,
.section--four .area > .slim .foot,
.section--four .area > .slim .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--four .area > .slim.cb-album .body {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--four .area > .slim .tiny {
  width: 100%;
}
.section--one .area {
  width: calc(100% + 30px);
  margin-left: -15px;
}
.section--two .area {
  width: calc(100% + 20px);
  margin-left: -10px;
}
.section--four .area {
  width: calc(100% + 60px);
  margin-left: -30px;
}
.section--one .area .flat.slim {
  padding-top: 80px;
  box-sizing: border-box;
}
@media (max-width: 1023px) {
  .section--one .area .edge.slim {
    width: 98.29059829%;
  }
}
.section--two .area .unit {
  margin-left: 10px;
  margin-right: 10px;
}
.section--two .area .pure.wide {
  width: calc(100% - 20px);
}
.section--two .area .pure.slim {
  width: calc(50% - 20px);
}
.section--two .area .seam.wide {
  width: calc((100%/1420*888) - 20px);
}
.section--two .area .seam.slim {
  width: calc((100%/1420*532) - 20px);
}
.section--four .area {
  width: 106.59340659%;
  margin-left: -3.2967033%;
}
.section--four .area .slim.pure .lottieicon {
  padding: 0 80px;
  box-sizing: border-box;
}
.section--four .area .seam.slim {
  width: 27.11340206%;
}
.section--four .area .seam.slim .lottieicon {
  padding: 0 45px;
  box-sizing: border-box;
}
@media (max-width: 1023px) {
  .section--six .area .unit {
    padding: 0 8.54700855%;
    box-sizing: border-box;
  }
  .section--six .area .unit .body {
    width: 102.57731959%;
    margin-left: -1.25628141%;
  }
  .section--six .area .unit .part {
    margin-left: 1.25628141%;
    margin-right: 1.25628141%;
  }
  .section--six .area .unit .part.tall {
    width: 97.48743719%;
  }
  .section--six .area .unit .part.tiny {
    width: 47.48743719%;
  }
}
/*# sourceMappingURL=./screen-medium.css.map */